<template>
    <div class="container">
        <div class="container_header">
            <Index_nav></Index_nav>
        </div>
        <div class="container_center"></div>
        <div class="container_footer"></div>
    </div>
</template>

<script>
import Index_nav from '../components/Index_nav.vue';
export default {
    name: "Index2",
    data() {
        return {};
    },
    methods: {},
    components: { Index_nav }
}
</script>

<style>
.container {
    position: fixed;
    width: 100%;
    height: 100%;
    background-image: url('../assets/3.png');
    background-position: center center;
    background-size: cover;
    color: white;
}
</style>