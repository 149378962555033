<template>
  <!-- <div class="note_nav_btn">
    
  </div> -->
  <img class="iconfont icon-add-circle"/>
</template>

<script>
export default {
}
</script>

<style>
    .note_nav_btn{
        width:1.5em;
        height: 1.5em;
        background-color: green;
        display: flex;
    }
</style>