<template>
  <div class="note_open">
    <div class="note_open_title">
      笔记列表
    </div>
    <Note_open_info v-for="item in this.noteList" :key="item.id" :value="item.id" :obj="{item}"></Note_open_info>
  </div>
</template>

<script>
import Note_open_info from './Note_open_info.vue';
export default {
  name: "note_open",
  components: { Note_open_info },
  data() {
    return {
      noteList: ''
    }
  },
  async created() {
    let resp = await this.$http.get('/note/getAllNote')
    this.noteList = resp.data;
  }
}
</script>

<style>
.note_open {
  background-color: white;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ECECEC;
}

.note_open_title {
  width: 100%;
  height: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2DBE60;
  letter-spacing: 10px;
  font-weight: 500;
  color: white;
  border-bottom: 1px solid #ECECEC;
}
</style>