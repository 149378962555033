<template>
    <div class="note_content">
        这里是文本区域,qingshuru 
    </div>

</template>

<script>
export default {
    data(){
        return{
            content:''
        }
    }

}
</script>

<style>
</style>