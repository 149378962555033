<template>
    <div class="container_main">
        <div class="left">
            <Note_nav/>
        </div>
        <div class="center">
            <Note_open/>
        </div>
        <div class="right">
            <Note_content/>
        </div>
    </div>
</template>

<script>
import Note_nav from '../components/note/Note_nav.vue'
import Note_open from '../components/note/Note_open.vue'
import Note_content from '../components/note/Note_content.vue';

export default {
    name: "Note",
    data() {
        return {
            note: ""
        };
    },
    components: { Note_nav, Note_open, Note_content }
}
</script>

<style>
.container_main {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
}

.left {
    height: 100%;
    width: 4%;
}

.center {
    height: 100%;
    width: 18%;
}

.right {
    height: 100%;
    width: 78%;
}
</style>