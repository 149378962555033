<template>

    <div class="wrap_note" @click="showTextContent">
        <div class="oneNote">
            <div class="note_info_title">{{this.obj.item.title}}</div>
            <div class="note_info_dateTime">{{this.obj.item.lastTime}}</div>
            <div class="note_info_content">{{this.obj.item.content}}</div>
        </div>
    </div>

</template>

<script>
export default {
    data(){
        return{

        }
    },
    methods:{
        showTextContent(){
            
        }
    },
    props:{
        obj:{
            type: Object,
            required: false
        }
    },
    created(){
        console.log(this.obj)
    }
}
</script>

<style>
.oneNote {
    width: 88%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #ECECEC;

}

.wrap_note {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 13%;

}

.wrap_note:hover {
    background-color: #2DBE60;
    color: white;
    cursor: pointer;
}
.wrap_note:hover .note_info_content{
    color: white;
}
.wrap_note:hover .note_info_dateTime{
    color: white;
}
.note_info_title {
    display: flex;
    flex: 1;
    align-items: center;
    margin: 5px 0;
}

.note_info_dateTime {
    display: flex;
    flex: 1;
    align-items: center;
    color: #878787;

}

.note_info_content {
    display: flex;
    flex: 3;
    color: #878787;
    font-size: small;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 5px 0;

}
</style>