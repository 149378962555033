<template>
    <div class="index_nav">
        <div class="index_nav_logo" @click="home">FirstClass</div>
        <div class="wrap_index_nav_btn">
            <div class="index_nav_btn" @click="note">笔记</div>
            <div class="index_nav_btn" @click="forum">江湖</div>
            <div class="index_nav_btn" @click="nav">导航</div>
        </div>
        <div class="index_nav_user"></div>
    </div>
</template>

<script>
export default {
    methods: {
        home() {
            this.$router.push('/index2')
        },
        nav() {
            this.$router.push('/nav')
        },
        note() {
            this.$router.push('/note')
        },
        forum() {
            this.$router.push('/forum')
        }
    }

}
</script>

<style>
.index_nav {
    width: 100%;
    height: 10%;
    display: flex;
    padding: 10px 30px;
}

.index_nav_logo {
    font-weight: 900;
    font-size: 30px;
    display: flex;
    flex: 1;
    justify-content: center;
}

.index_nav_logo:hover {
    cursor: pointer;
}

.wrap_index_nav_btn {
    display: flex;
    flex: 6;
    align-items: center;
}

.index_nav_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 20px;
    margin: 8px 20px;
    border-radius: 20px;

}

.index_nav_btn:hover {
    background: grey;
    cursor: pointer;
}

.index_nav_user {
    display: flex;
    flex: 2;
}
</style>