<template>
    <div class="container_main">
        <div>这里是导航页</div>
    </div>
</template>

<script>
export default {
    name:'Nav',
    data(){
        return{

        }
    }
}
</script>