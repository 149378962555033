<template>
    <div>
        <div class="account">
            <el-input v-model="account" placeholder="账号"></el-input>
        </div>
        <div class="password">
            <el-input v-model="password" placeholder="密码"></el-input>
        </div>
    </div>
  
</template>

<script>
export default {
  name: 'Home',
  data(){
    return {
        account: '',
        password: ''
    }
  }
}
</script>