<template>
  <div class="container_main">
    <div>这就是江湖</div>
  </div>
</template>

<script>
export default {
 name: 'Forum',
    data(){
        return{

        }
    },
    methosd:{
        
    }
}
</script>

<style>

</style>