<template>
<div>
  <div class="login" @click="login">
    登录
  </div>
  <div class="register" @click="register">
    注册
  </div>
</div>

</template>

<script>


export default {
  name: 'Index',
  methods:{
    login(){
        this.$router.push("/login")
    },
    register(){
        
    }
  }
}
</script>