<template>
    <div>
        <div class="account">
           <el-input v-model="user.account" placeholder="账号"></el-input>
        </div>
        <div class="password">
           <el-input v-model="user.password" placeholder="密码"></el-input>
        </div>
        <div><button @click="login">登录</button></div>
        <div @click="getAllUser">获取所有用户信息</div>
        <div>{{userListInfo}}</div>
    </div>

</template>

<script>
// @ is an alias to /src

export default {
  name: 'Login',
  data(){ 
    return{
        userListInfo:'123',
        user:{
          account:'',
          password:''
        }
    }
  },
  methods:{
    async getAllUser(){
        let resp = await this.$http.get('/user/getAllUser')
        this.userListInfo = resp.data
    },
    async login(){
      let resp = await this.$http.post('/user/login',this.user)
      if(resp.data == 100){
        // this.$router.push("/nav")
        this.$router.push("/index2")
      }
      if(resp.data == 400){
        alert("密码或用户名错误")
      }
    }
  }
}
</script>