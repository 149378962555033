<template>
  <div class="note_nav">
    <div class="wrap_logo">
      <img class="note_nav_logo" src="../../assets/note.png" />

    </div>
    <div class="wrap_btn">
      <img class="btn_add" src="../../assets/note/nav_add_off.png" />
      <img src="../../assets/note/note_search_off.png" class="btn_search" />
    </div>


  </div>
</template>

<script>
import Note_btn from './Note_btn.vue';
export default {
  name: "note_nav",
  components: { Note_btn }
}
</script>

<style>
.note_nav {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #F8F8F8;
}

.note_nav_btn {
  background-color: greenyellow;
  margin: 15px 0;
  padding: 2px 3px;
}

.note_nav_btn:hover {
  cursor: pointer;
}

.wrap_logo {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.note_nav_logo {
  width: 70%;
}

.wrap_btn {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 10;
  align-items: center;
  color: green;
}

.iconfont {
  font-size: 30px !important;
}

.btn_search {
  width: 60%;
  margin-bottom: 10px;
}

.btn_search:hover {
  cursor: pointer;
  content: url('../../assets/note/note_search_on.png');
}

.btn_add {
  width: 60%;
  margin-bottom: 15px;
}

.btn_add:hover {
  cursor: pointer;
  content: url('../../assets/note/nav_add_on.png');
}
</style>